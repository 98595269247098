import { sleep } from "@components/Dashboards/QdDashboard/QdDashboard";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import {
  atomsInitializedAtom,
  attributionModelAtom,
  attributionWindowAtom,
  comparedAtom,
  endTimeAtom,
  newVsReturningAtom,
  previousEndTimeAtom,
  previousStartTimeAtom,
  reportTimeAtom,
  selectedMarketingChannelsAtom,
  selectedTimezoneAtom,
  showAttributionModeSurveyAtom,
  startTimeAtom,
  timeRangeKeyAtom,
} from "atoms";
import * as Sentry from "@sentry/browser";

import {
  AnalyticsData,
  AnalyticsResultRequest,
  BaseAnalytics,
  DailyBaseAnalytics,
} from "@api/types/backendTypes";
import {
  ATTRIBUTION_MODEL_OPTIONS,
  ATTRIBUTION_WINDOW_OPTIONS,
  AVAILABLE_MARKETING_CHANNEL_OPTIONS,
  REPORT_TIME_OPTIONS,
} from "constants/constants";
import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import { useAuth } from "../useAuth";
import {
  AnalyticsFilterType,
  requestAnalytics,
  requestAnalyticsResult,
  toAnalyticsRequestPayload,
} from "@api/services/analytics";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useAttributionSettingsOpen } from "@lib/hooks";
import { Influencer, fetchInfluencers } from "../useInfluencers";
import { Cooperation, fetchCooperations } from "../useCooperations";
import posthog from "posthog-js";
import * as NProgress from "nprogress";
import { useEffectOnce } from "usehooks-ts";
import { useInitializeAttributionSettings } from "@lib/hooks/use-initialize-attribution-settings";
export const adsDataAtom = atom<BaseAnalytics>({} as BaseAnalytics);
export const dailyAdsDataAtom = atom<DailyBaseAnalytics>(
  {} as DailyBaseAnalytics
);
export const jobIdAtom = atom<string>("");
export const fetchingAnalyticsAtom = atom<boolean>(false);
export const initializedAnalyticsAtom = atom<boolean>(false);
export const analyticsProgressAtom = atom<number>(0.0);
export const comparedProgressAtom = atom<number>(0.0);
export const analyticsFinishedAtom = atom<boolean>(true);
export const comparedAnalyticsFinishedAtom = atom<boolean>(true);

export type AnalyticsResultType =
  | (AnalyticsData & { compared?: AnalyticsData | null | undefined })
  | null
  | undefined;
export const firstRequestFinishedAtom = atom<boolean>(false);
export const analyticsPreviousDataAtom = atom<
  AnalyticsResultType | null | undefined
>(null);

const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_BASE_URL;

let globalJobId = "";
let globalJobIdComparedRequest = "";
let globalRefetchTimeout: NodeJS.Timeout;
let globalRefetchTimeoutComparedRequest: NodeJS.Timeout;

// we set this as a global variable, because we don't want to the refetched query
// to be saved with another query key, but replace the current data of the exact query
// with the new data we get when ignoring the ad-connector cache
// (queries get cached and saved with their respective props as the query key)
let ignoreAdInfoCache = false;

type FetchAnalyticsProps = {
  attributionModel: any;
  attributionWindow: any;
  token: any;
  collectAdInfo: any;
  collectAdInfoFqMode: any;
  collectAdKpiTimeline: any;
  collectAdKpis: any;
  endTime: any;
  localAiAttributionWindow?: number;
  lookbackWindow: number;
  fixedMarketingChannels: any;
  fullyQualifiedIds: any;
  reportTime: any;
  resultsWaitTime: any;
  selectedMarketingChannels: any;
  selectedStoreIds: any;
  selectedTimezone: any;
  startTime: any;
  timeRangeKey: string;
  useGuid: any;
  views: any;
  include?: Array<"influencers" | "cooperations">;
  cooperationsAsAdsets?: boolean;
  filter?: AnalyticsFilterType;
  organisationId: string;
  requiredChannel?: string;
  isComparedRequest: boolean;
  disablePosthogEvent: boolean;
  nvr: "default" | "new" | "returning" | "all";
};

type SetterType = ({
  progress,
  finished,
  showNvrTooltip,
}: {
  progress: number;
  finished: boolean;
  showNvrTooltip: boolean;
}) => void;

const getAttributionWindowFromAov = (aov: number) => {
  if (aov < 80) return 14;
  if (aov < 150) return 28;
  if (aov < 500) return 60;
  return 80;
};

const checkHasData = (data: AnalyticsResultType, channel?: string) => {
  // display a warning when no results are found
  let numEntries = 0;
  let numNvrEntries = 0;
  let numDailyNvrEntries = 0;
  let numDailyEntries = 0;
  let numCJEntries = 0;
  let numInfluencerEntries = 0;
  let hasChannelData = false;
  // unknown is only here for typescript to stop complaining
  const analytics = data as unknown as AnalyticsResultType;
  if (analytics?.aggregated) {
    numEntries = analytics?.aggregated.ads?.size || 0;
    if (channel && !hasChannelData) {
      hasChannelData =
        (analytics?.aggregated.ads?.get(channel)?.length ?? 0) > 0;
    }
  }
  if (analytics?.daily) {
    numDailyEntries = Object.values(analytics.daily)
      .map((el) => el.ads.size || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (channel && !hasChannelData) {
      hasChannelData =
        Object.values(analytics.daily)
          .map((el) => el.ads.get(channel)?.length || 0)
          .reduce((prev, cur) => prev + cur, 0) > 0;
    }
  }
  if (analytics?.nvr_aggregated) {
    numNvrEntries = analytics?.nvr_aggregated?.all?.ads?.size || 0;
    if (channel && !hasChannelData) {
      hasChannelData =
        (analytics?.nvr_aggregated?.all?.ads?.get(channel)?.length ?? 0) > 0;
    }
  }
  if (analytics?.nvr_daily) {
    numDailyNvrEntries = Object.values(analytics.nvr_daily)
      .map((el) => el.all?.ads.size || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (channel && !hasChannelData) {
      hasChannelData =
        Object.values(analytics.nvr_daily)
          .map((el) => el.all?.ads.get(channel)?.length || 0)
          .reduce((prev, cur) => prev + cur, 0) > 0;
    }
  }
  if (analytics?.customer_journey) {
    numCJEntries = Object.values(analytics.customer_journey)?.length;
    hasChannelData =
      (Object.values(analytics.customer_journey)?.length ?? 0) > 0;
  }
  if (analytics?.customer_journey_tc) {
    numCJEntries = Object.values(analytics.customer_journey_tc)?.length;
    hasChannelData =
      (Object.values(analytics.customer_journey_tc)?.length ?? 0) > 0;
  }
  if (analytics?.tc_aggregated) {
    numInfluencerEntries = analytics.tc_aggregated.ads.size;
    if (channel && !hasChannelData) {
      hasChannelData =
        (analytics.tc_aggregated.ads.get(channel)?.length ?? 0) > 0;
    }
  }
  if (analytics?.tc_daily) {
    numDailyNvrEntries = Object.values(analytics.tc_daily)
      .map((el) => el.ads?.size || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (channel && !hasChannelData) {
      hasChannelData =
        Object.values(analytics.tc_daily)
          .map((el) => el.ads.get(channel)?.length || 0)
          .reduce((prev, cur) => prev + cur, 0) > 0;
    }
  }
  if (analytics?.nvr_tc_aggregated) {
    numInfluencerEntries = analytics.nvr_tc_aggregated.all?.ads?.size;
    if (channel && !hasChannelData) {
      hasChannelData =
        (analytics.nvr_tc_aggregated.all?.ads.get(channel)?.length ?? 0) > 0;
    }
  }
  if (analytics?.nvr_tc_daily) {
    numDailyNvrEntries = Object.values(analytics.nvr_tc_daily)
      .map((el) => el.all?.ads.size || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (channel && !hasChannelData) {
      hasChannelData =
        Object.values(analytics.nvr_tc_daily)
          .map((el) => el.all?.ads.get(channel)?.length || 0)
          .reduce((prev, cur) => prev + cur, 0) > 0;
    }
  }
  if (analytics?.tc_oid_aggregated) {
    numInfluencerEntries = analytics.tc_oid_aggregated.ads.size;
    if (channel && !hasChannelData) {
      hasChannelData =
        (analytics.tc_oid_aggregated.ads.get(channel)?.length ?? 0) > 0;
    }
  }
  if (analytics?.tc_oid_daily) {
    numDailyNvrEntries = Object.values(analytics.tc_oid_daily)
      .map((el) => el.ads?.size || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (channel && !hasChannelData) {
      hasChannelData =
        Object.values(analytics.tc_oid_daily)
          .map((el) => el.ads.get(channel)?.length || 0)
          .reduce((prev, cur) => prev + cur, 0) > 0;
    }
  }
  if (analytics?.nvr_tc_oid_aggregated) {
    numInfluencerEntries = analytics.nvr_tc_oid_aggregated.all?.ads?.size;
    if (channel && !hasChannelData) {
      hasChannelData =
        (analytics.nvr_tc_oid_aggregated.all?.ads.get(channel)?.length ?? 0) >
        0;
    }
  }
  if (analytics?.nvr_tc_oid_daily) {
    numDailyNvrEntries = Object.values(analytics.nvr_tc_oid_daily)
      .map((el) => el.all?.ads.size || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (channel && !hasChannelData) {
      hasChannelData =
        Object.values(analytics.nvr_tc_oid_daily)
          .map((el) => el.all?.ads.get(channel)?.length || 0)
          .reduce((prev, cur) => prev + cur, 0) > 0;
    }
  }

  if (
    (numEntries === 0 &&
      numDailyEntries === 0 &&
      numDailyNvrEntries === 0 &&
      numNvrEntries === 0 &&
      numCJEntries === 0 &&
      numInfluencerEntries === 0) ||
    (channel && !hasChannelData)
  ) {
    return false;
  }
  return true;
};

const onSuccessHandler = (
  data: AnalyticsData | null,
  fetchDataSetter: SetterType,
  requiredChannel?: string
) => {
  ignoreAdInfoCache = false;
  // reset progress to avoid flickering in UI when fetching new data
  fetchDataSetter({ progress: 0, finished: true, showNvrTooltip: false });

  // const hasData = checkHasData(data, requiredChannel);
  // if (!hasData) {
  //   toast.warning("Specified time-range did not provide any results.", {
  //     id: "no-attribution-warning",
  //   });
  // }
};

const onFetchData = async (
  {
    attributionModel,
    attributionWindow,
    token,
    collectAdInfo,
    collectAdInfoFqMode,
    collectAdKpiTimeline,
    collectAdKpis,
    endTime,
    timeRangeKey,
    fixedMarketingChannels,
    fullyQualifiedIds,
    reportTime,
    lookbackWindow,
    resultsWaitTime,
    selectedMarketingChannels,
    selectedStoreIds,
    selectedTimezone,
    startTime,
    useGuid,
    views,
    include,
    cooperationsAsAdsets,
    filter,
    organisationId,
    nvr,
    localAiAttributionWindow,
    requiredChannel,
    isComparedRequest,
    disablePosthogEvent,
  }: FetchAnalyticsProps,
  setter: SetterType,
  signal?: AbortSignal,
  comparedRequest?: boolean
) => {
  if (!token || !BACKEND_URL) return null;
  if (!selectedStoreIds?.length) {
    throw new Error("You have to select a store before fetching data.");
  }
  if (comparedRequest) {
    if (globalRefetchTimeoutComparedRequest)
      clearTimeout(globalRefetchTimeoutComparedRequest);
  } else {
    if (globalRefetchTimeout) clearTimeout(globalRefetchTimeout);
  }
  setter({ progress: 0, finished: false, showNvrTooltip: false });
  const startTimeInSec = dayjs().unix();

  // get payload for request
  const payload = toAnalyticsRequestPayload({
    storeIds: selectedStoreIds,
    startTime,
    endTime,
    attributionModel,
    reportTime,
    attributionWindow: attributionWindow,
    lookbackWindow,
    selectedTimezone: selectedTimezone.toString(),
    adRestrictionList:
      fixedMarketingChannels && fixedMarketingChannels.length > 0
        ? fixedMarketingChannels
        : selectedMarketingChannels,
    useGuid,
    collectAdInfo,
    collectAdKpiTimeline,
    collectAdKpis,
    collectAdInfoFqMode,
    fullyQualifiedIds,
    ignoreAdInfoCache,
  });

  const collectStart = dayjs(payload.collect_start);
  const collectEnd = dayjs(payload.collect_end);

  if (collectEnd.unix() - collectStart.unix() >= 60 * 60 * 24 * 100) {
    throw new Error("Invalid request range!");
  }

  let influencerData: Influencer[] | undefined = undefined;
  let cooperationData: Cooperation[] | undefined = undefined;
  if (include?.includes("influencers")) {
    try {
      influencerData = await fetchInfluencers({
        token,
        organisationId,
        csids: selectedStoreIds,
      });
    } catch (error: any) {
      Sentry?.captureException("Failed to fetch influencer data.", error);
    }
  }
  if (include?.includes("cooperations")) {
    try {
      cooperationData = await fetchCooperations({
        token,
        organisationId,
        csids: selectedStoreIds,
      });
    } catch (error: any) {
      Sentry?.captureException("Failed to fetch cooperation data.", error);
    }
  }
  const analyticsStart = Date.now();
  // retrieve ticket
  const result = await requestAnalytics({
    requestBody: { ...payload, views, timezone: selectedTimezone.toString() },
    backendUrl: BACKEND_URL,
    token: token,
    signal,
    influencerData,
    cooperationData,
    filter,
    cooperationsAsAdsets,
  }).catch((err) => {
    throw new Error(err);
  });
  const posthogPayload = {
    platform: "dashboard",
    method: "manual",
    attribution_window: attributionWindow,
    report_time: reportTime,
    attribution_model: attributionModel,
    touchpoints: payload.ads_scope,
    csids: selectedStoreIds,
    nvr: nvr,
    utc_offset: payload.utc_offset,
    date_range: timeRangeKey,
    start_date: payload.attributions[0].attribution_start,
    end_date: payload.attributions[0].attribution_end,
    compare_previous_date_range: isComparedRequest,
  };
  if (!disablePosthogEvent) {
    posthog?.capture("Attribution data requested", posthogPayload);
  }
  if (!result) return null;
  if (typeof result === "object" && result.analytics) {
    // we get the analytics data directly from cache
    setter({
      progress: result.progress,
      finished: result.finished,
      showNvrTooltip: false,
    });

    const analytics = result.analytics;
    if (!disablePosthogEvent) {
      posthog?.capture("Attribution data received", {
        ...posthogPayload,
        request_duration: (Date.now() - analyticsStart) / 1000,
      });
    }
    return analytics;
  } else if (typeof result === "string") {
    const localJobid = result;
    // set current jobid
    if (comparedRequest) {
      globalJobIdComparedRequest = localJobid;
    } else {
      globalJobId = localJobid;
    }

    // wait for result
    const resultsRequestData = {
      csids: selectedStoreIds,
      jobid: localJobid,
    } as AnalyticsResultRequest;
    let finishedFlag = false;
    let analytics: AnalyticsData | null = null;
    while (!finishedFlag) {
      if (comparedRequest) {
        if (
          globalJobIdComparedRequest &&
          globalJobIdComparedRequest !== localJobid
        ) {
          break;
        }
      } else {
        if (globalJobId && globalJobId !== localJobid) {
          break;
        }
      }
      await sleep(resultsWaitTime);
      const stats = await requestAnalyticsResult({
        requestBody: resultsRequestData,
        backendUrl: BACKEND_URL,
        token: token,
        timeSettings: {
          timezone: selectedTimezone?.toString(),
          utcOffset: payload.utc_offset,
          startTime: dayjs(payload.attributions[0].attribution_start)
            .tz("UTC", true)
            .toISOString(),
          endTime: dayjs(payload.attributions[0].attribution_end)
            .tz("UTC", true)
            .toISOString(),
        },
        influencerData,
        cooperationData,
        cooperationsAsAdsets,
        filter,
        signal,
        views,
      }).catch(async (err) => {
        throw new Error(err);
      });
      if (comparedRequest) {
        if (
          globalJobIdComparedRequest &&
          globalJobIdComparedRequest !== localJobid
        ) {
          break;
        }
      } else {
        if (globalJobId && globalJobId !== localJobid) {
          break;
        }
      }
      if (!stats) return;
      // set job stats
      const progressVal = stats.progress || 0.0;
      finishedFlag = stats.finished || false;
      analytics = stats.analytics;
      const currentTimeInSec = dayjs().unix();
      const hasNvrViews =
        views.findIndex((el: string) => el.includes("nvr_")) !== -1;
      const showNvrTooltip =
        currentTimeInSec - startTimeInSec > 12 &&
        progressVal < 80 &&
        hasNvrViews;
      setter({ progress: progressVal, finished: finishedFlag, showNvrTooltip });
    }
    if (!disablePosthogEvent) {
      posthog?.capture("Attribution data received", {
        ...posthogPayload,
        request_duration: (Date.now() - analyticsStart) / 1000,
      });
    }
    if (comparedRequest) {
      if (finishedFlag) {
        globalJobIdComparedRequest = "";
      }
    } else {
      if (finishedFlag) {
        globalJobId = "";
      }
    }

    onSuccessHandler(analytics, setter, requiredChannel);
    return analytics;
  }
};

export type UseAnalyticsProps = {
  useGuid?: boolean;
  collectAdInfo?: boolean;
  collectAdKpiTimeline?: boolean;
  collectAdKpis?: boolean;
  collectAdInfoFqMode?: boolean;
  fullyQualifiedIds?: true;
  autoRefetch?: boolean;
  autoFirstFetch?: boolean;
  views?: Array<
    | "daily"
    | "aggregated"
    | "nvr_aggregated"
    | "nvr_daily"
    | "tc_aggregated"
    | "tc_daily"
    | "nvr_tc_aggregated"
    | "nvr_tc_daily"
    | "customer_journey"
    | "customer_journey_tc"
    | "tc_oid_aggregated"
    | "tc_oid_daily"
    | "nvr_tc_oid_aggregated"
    | "nvr_tc_oid_daily"
  >;
  fixedReportTime?: (typeof REPORT_TIME_OPTIONS)[number]["value"];
  fixedAttributionWindow?: (typeof ATTRIBUTION_WINDOW_OPTIONS)[number]["value"];
  fixedAttributionModel?: (typeof ATTRIBUTION_MODEL_OPTIONS)[number]["value"];
  fixedMarketingChannels?: Array<
    (typeof AVAILABLE_MARKETING_CHANNEL_OPTIONS)[number]["value"]
  >;
  include?: Array<"influencers" | "cooperations">;
  cooperationsAsAdsets?: boolean;
  filter?: AnalyticsFilterType;
  useDefaultMarketingChannels?: boolean;
  requiredChannel?: string;
  enableCompare?: boolean;
};

export type UseAnalyticsOptions = {
  enabled?: boolean;
  onSuccess?: ((data: AnalyticsData | null) => void) | undefined;
  onFetchingNewData?: () => void;
};

let timeoutHandler: NodeJS.Timeout | null = null;

let surveyTimeoutHandler: NodeJS.Timeout | null = null;
function useAnalytics<TData = AnalyticsData>(
  {
    useGuid = true,
    collectAdInfo = true,
    collectAdInfoFqMode = true,
    collectAdKpiTimeline = false,
    collectAdKpis = true,
    fullyQualifiedIds = true,
    views: viewsProp,
    fixedMarketingChannels,
    fixedReportTime,
    fixedAttributionWindow,
    fixedAttributionModel,
    include,
    cooperationsAsAdsets,
    filter,
    useDefaultMarketingChannels,
    requiredChannel,
    enableCompare = false,
  }: UseAnalyticsProps,
  queryOptions?: UseAnalyticsOptions
) {
  useInitializeAttributionSettings({
    requiredChannel,
    enabled: Boolean(requiredChannel),
  });
  const [showNvrTooltip, setShowNvrTooltip] = useState(false);
  const [atomsInitialized] = useAtom(atomsInitializedAtom);
  const [previousResult, setPreviousResult] = useAtom(
    analyticsPreviousDataAtom
  );
  const [showAttributionModeSurvey, setShowAttributionModeSurvey] = useAtom(
    showAttributionModeSurveyAtom
  );

  const { data: auth } = useAuth();
  const { selectedStoreIds, selectedOrganisation } = useSelectedStores();
  const [startTime] = useAtom(startTimeAtom);
  const [endTime] = useAtom(endTimeAtom);
  const [timeRangeKey] = useAtom(timeRangeKeyAtom);
  const [attributionModel] = useAtom(attributionModelAtom);
  const [attributionWindow] = useAtom(attributionWindowAtom);
  const [reportTime] = useAtom(reportTimeAtom);
  const [selectedTimezone] = useAtom(selectedTimezoneAtom);
  const [selectedMarketingChannels] = useAtom(selectedMarketingChannelsAtom);
  const views = useMemo(() => viewsProp ?? ["aggregated"], [viewsProp]);
  const [progress, setProgress] = useAtom(analyticsProgressAtom);
  const [comparedProgress, setComparedProgress] = useAtom(comparedProgressAtom);
  const [finished, setFinished] = useAtom(analyticsFinishedAtom);
  const [comparedFinished, setComparedFinished] = useAtom(
    comparedAnalyticsFinishedAtom
  );
  const [resultsWaitTime] = useState(1000);
  const [newVsReturning] = useAtom(newVsReturningAtom);
  const [compared] = useAtom(comparedAtom);
  const [previousStartTime] = useAtom(previousStartTimeAtom);
  const [previousEndTime] = useAtom(previousEndTimeAtom);
  const settingsOpenState = useAttributionSettingsOpen();
  const [selectedStoresAfterClose, setSelectedStoresAfterClose] =
    useState(selectedStoreIds);
  const [selectedOrganisationAfterClose, setSelectedOrganisationAfterClose] =
    useState(selectedOrganisation);

  useEffect(() => {
    if (settingsOpenState === false) {
      if (selectedStoreIds.join(",") !== selectedStoresAfterClose.join(",")) {
        setPreviousResult(null);
      }
      setSelectedStoresAfterClose(selectedStoreIds);
      setSelectedOrganisationAfterClose(selectedOrganisation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreIds.join(","), selectedOrganisation, settingsOpenState]);

  const fetchDataSetter = useCallback<SetterType>(
    ({ progress, finished, showNvrTooltip }) => {
      setProgress(progress);
      setFinished(finished);
      setShowNvrTooltip(showNvrTooltip);
    },
    [setFinished, setProgress]
  );

  const fetchComparedDataSetter = useCallback<SetterType>(
    ({ progress, finished, showNvrTooltip }) => {
      setComparedProgress(progress);
      setComparedFinished(finished);
      setShowNvrTooltip(showNvrTooltip);
    },
    [setComparedFinished, setComparedProgress]
  );

  // TODO analytics query is disabled until analyticsjobs query is finished
  // return loading state together with loading state from analyticsjobs

  const partialObj = useMemo<Partial<FetchAnalyticsProps>>(
    () => {
      return {
        reportTime: fixedReportTime ? fixedReportTime : reportTime,
        attributionModel: fixedAttributionModel
          ? fixedAttributionModel
          : attributionModel,
        attributionWindow: Number(
          fixedAttributionWindow ? fixedAttributionWindow : attributionWindow
        ),
        selectedMarketingChannels: fixedMarketingChannels
          ? fixedMarketingChannels
          : selectedMarketingChannels,
        selectedTimezone,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fixedReportTime,
      reportTime,
      attributionModel,
      fixedAttributionModel,
      fixedAttributionWindow,
      attributionWindow,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      fixedMarketingChannels?.join(","),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectedMarketingChannels.join(","),
      selectedTimezone,
    ]
  );

  // for some values we want to wait a few secs before fetching new data
  // to give the user the chance to adjust them in bulk and prevent
  // spamming unwanted requests to hive
  const [debouncedPartialObj, setDebouncedPartialObj] =
    useState<Partial<FetchAnalyticsProps>>(partialObj);

  useEffect(
    () => {
      // Update debounced value after delay
      if (settingsOpenState === false) {
        timeoutHandler = setTimeout(() => {
          setDebouncedPartialObj(partialObj);
        }, 2000);
      }

      // Cancel the timeout if value changes
      // This is how we prevent debounced value from updating if value is changed
      // within the delay period. Timeout gets cleared and restarted.
      return () => {
        if (timeoutHandler) {
          clearTimeout(timeoutHandler);
          timeoutHandler = null;
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialObj, settingsOpenState] // Only re-call effect if value changes
  );

  useEffect(
    () => {
      // Only instantly apply when menu was closed when updating the settings
      if (settingsOpenState === false) {
        setDebouncedPartialObj(partialObj);
        timeoutHandler && clearTimeout(timeoutHandler);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialObj] // Only re-call effect if value changes
  );

  // for these changes we want to instantly fetch new data
  const instantPartialObj = useMemo<Partial<FetchAnalyticsProps>>(() => {
    return {
      token: auth?.token as string,
      collectAdInfo,
      selectedStoreIds: selectedStoresAfterClose,
      collectAdInfoFqMode,
      collectAdKpis,
      collectAdKpiTimeline,
      resultsWaitTime,
      useGuid,
      startTime,
      endTime,
      views,
      include,
      cooperationsAsAdsets,
      filter,
      compared: compared && enableCompare,
      previousStartTime,
      previousEndTime,
      organisationId: selectedOrganisationAfterClose,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.token,
    collectAdInfo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedStoresAfterClose.join(","),
    selectedOrganisationAfterClose,
    collectAdInfoFqMode,
    collectAdKpis,
    collectAdKpiTimeline,
    cooperationsAsAdsets,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fixedMarketingChannels?.join(","),
    fixedAttributionModel,
    fixedAttributionWindow,
    fullyQualifiedIds,
    resultsWaitTime,
    useGuid,
    startTime,
    compared,
    enableCompare,
    previousEndTime,
    previousStartTime,
    endTime,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    views.join(","),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    include?.join(","),
  ]);

  const obj = useMemo(() => {
    return {
      ...partialObj,
      ...instantPartialObj,
    } as FetchAnalyticsProps & {
      compared: boolean;
      previousStartTime: string;
      previousEndTime: string;
    };

    // we disable linting here because we want to rerun this memo when either the instant
    // or the debounced part changed. But in the object we return above, we use the "partialObj"
    // that also instantly changes, to avoid having the situation where the "instantPartialObj" changes
    // we calculate a new obj and then after the debounce we again get new values and have to cancel
    // the old query because the props have changed
    // with this method we always have the current settings as our query props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPartialObj, instantPartialObj]);

  const initialized = useMemo(() => {
    if (requiredChannel) {
      return obj.selectedMarketingChannels.includes(
        requiredChannel === "influencer_module" ? "influencer" : requiredChannel
      );
    } else {
      return obj.attributionModel !== "isolated";
    }
  }, [obj.attributionModel, obj.selectedMarketingChannels, requiredChannel]);

  // whenever one of the parts change, we want to update our data object
  // which then triggers a new request

  // this query should only be enabled when we have all the data available
  const dataAvailable = useMemo(
    () =>
      initialized &&
      atomsInitialized &&
      obj.selectedStoreIds.length > 0 &&
      obj.selectedMarketingChannels.length > 0 &&
      !!obj.startTime &&
      !!obj.organisationId &&
      !!obj.endTime &&
      !!obj.attributionModel &&
      !!obj.attributionWindow &&
      !!obj.reportTime &&
      !!obj.startTime &&
      !!obj.endTime &&
      !!obj.selectedTimezone &&
      !!obj?.token,
    [
      initialized,
      atomsInitialized,
      obj.selectedStoreIds.length,
      obj.selectedMarketingChannels.length,
      obj.startTime,
      obj.organisationId,
      obj.endTime,
      obj.attributionModel,
      obj.attributionWindow,
      obj.reportTime,
      obj.selectedTimezone,
      obj?.token,
    ]
  );
  const options = useMemo(() => {
    return queryOptions ? queryOptions : {};
  }, [queryOptions]);

  const enabled = Boolean(
    options?.enabled !== undefined
      ? options?.enabled && dataAvailable
      : dataAvailable
  );
  const analyticsQuery = useQuery({
    queryKey: ["analytics", obj],
    queryFn: async ({ signal, queryKey }) => {
      if (dataAvailable) {
        const startDate = dayjs(obj.startTime);
        const endDate = dayjs(obj.endTime);

        const days = endDate.diff(startDate, "day");
        // we don't want to fetch data for more than 100 days, as it would throw an error
        if (days + Number(obj.attributionWindow) >= 100) {
          toast.error(
            "Please limit your attribution window and date range to a total of 100 days combined.",
            { id: "no-attribution-warning" }
          );

          return null;
        }
        // // call this function which gets passed as a prop
        // // mostly to notify the useAnalytics consumers that we are
        // // fetching data here and they may want to update their UI
        // onFetchingNewData ? onFetchingNewData() : null;
        try {
          const promises = [
            onFetchData(
              {
                ...obj,
                nvr: newVsReturning,
                timeRangeKey,
                requiredChannel,
                isComparedRequest: obj.compared,
                disablePosthogEvent: false,
              },
              fetchDataSetter,
              signal
            ),
          ];
          if (obj.compared && obj.previousStartTime && obj.previousEndTime) {
            promises.push(
              onFetchData(
                {
                  ...obj,
                  nvr: newVsReturning,
                  startTime: obj.previousStartTime,
                  endTime: obj.previousEndTime,
                  timeRangeKey,
                  requiredChannel,
                  isComparedRequest: obj.compared,
                  disablePosthogEvent: true,
                },
                fetchComparedDataSetter,
                signal,
                true
              )
            );
          }
          const [analyticsData, previousAnalyticsData] =
            await Promise.all(promises);
          if (obj.compared && obj.previousStartTime && obj.previousEndTime) {
            return {
              ...analyticsData,
              compared: previousAnalyticsData,
            } as AnalyticsResultType;
          }
          return analyticsData as AnalyticsResultType;
        } catch (error: any) {
          fetchDataSetter({
            progress: 0,
            finished: true,
            showNvrTooltip: false,
          });
          if (obj.compared) {
            fetchComparedDataSetter({
              progress: 0,
              finished: true,
              showNvrTooltip: false,
            });
          }

          throw error;
        }
      } else {
        return null;
      }
    },
    ...options,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    enabled: enabled,
    retry: 1,
  });

  const refetch = useCallback(() => {
    ignoreAdInfoCache = true;
    let hasChangedObj = false;
    for (const [key, value] of Object.entries(partialObj)) {
      // check if we have changed values that are not applied because of our debounce
      const debouncedVal =
        debouncedPartialObj[key as keyof typeof debouncedPartialObj];
      let valueHasChanged = debouncedVal !== value;

      if (Array.isArray(debouncedVal)) {
        valueHasChanged =
          JSON.stringify(debouncedVal) !== JSON.stringify(value);
      }

      if (valueHasChanged) {
        setDebouncedPartialObj({
          ...partialObj,
        } as Partial<FetchAnalyticsProps>);
        if (timeoutHandler) {
          clearTimeout(timeoutHandler);
          timeoutHandler = null;
        }
        hasChangedObj = true;
      }
    }
    if (hasChangedObj) {
      // we return here, because our query will automatically run again because of changed values
      // that haven't been aplied because of the debounce
      return;
    }
    // if we don't return above, we just call the refetch function to fetch new data
    return analyticsQuery.refetch();
  }, [analyticsQuery, debouncedPartialObj, partialObj]);
  const progressRate = obj.compared
    ? Math.min(progress, comparedProgress)
    : progress;
  useEffectOnce(() => {
    NProgress.configure({
      showSpinner: false,
      trickleSpeed: 2500,
      template:
        '<div class="bar" style="height:4px; background: var(--color-primary)" role="bar"><div class="peg" style="box-shadow: 0 0 10px var(--color-primary), 0 0 5px var(--color-primary);"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
    });
  });
  useEffect(() => {
    if (progressRate === 0 || progressRate === 100) {
      if (finished) NProgress.done();
      else NProgress.start();
    } else {
      NProgress.set(progressRate / 100);
    }
    if (finished && analyticsQuery.data) {
      setPreviousResult(analyticsQuery.data);
    }
  }, [analyticsQuery.data, finished, progressRate, setPreviousResult]);

  const hasData = useMemo(() => {
    const hasPreviousResult = checkHasData(
      previousResult ?? null,
      requiredChannel
    );
    if (analyticsQuery.isFetching && hasPreviousResult) return true;
    return checkHasData(analyticsQuery.data ?? null, requiredChannel);
  }, [
    previousResult,
    requiredChannel,
    analyticsQuery.isFetching,
    analyticsQuery.data,
  ]);
  const [firstRequestFinished, setFirstRequestFinished] = useAtom(
    firstRequestFinishedAtom
  );

  useEffect(() => {
    if (!firstRequestFinished && hasData) {
      setFirstRequestFinished(true);
    }
  }, [hasData, firstRequestFinished, setFirstRequestFinished]);

  useEffect(() => {
    if (
      firstRequestFinished &&
      !surveyTimeoutHandler &&
      !showAttributionModeSurvey
    ) {
      surveyTimeoutHandler = setTimeout(() => {
        setShowAttributionModeSurvey(true);
      }, 60000);
    }
  }, [
    firstRequestFinished,
    setShowAttributionModeSurvey,
    showAttributionModeSurvey,
  ]);

  return {
    progress: progressRate,
    finished: obj.compared ? finished && comparedFinished : finished,
    showNvrTooltip,
    ...analyticsQuery,
    data: analyticsQuery.data ? analyticsQuery.data : previousResult,
    refetch,
    isFetching:
      analyticsQuery.isFetching || analyticsQuery.isPending || !enabled,
    hasData,
  };
}
export { useAnalytics };
